const axios = async() => {
    const { default: axios } = await import(/* webpackChunkName: "axios" */ 'axios');

    return axios;
};

const CLASS_NAME_SHOW = 'show';
const HEADER_SCROLL_OFFSET = 50;

document.addEventListener('DOMContentLoaded', () => {

    const isScrolled = (scrollEl, scrollOffset) => {
        return (scrollEl.scrollTop > scrollOffset);
    }

    const handleScrollClass = (scroll, element, isScrolled) => {
        if (isScrolled) {
            element.classList.add('scrolled');
        } else {
            element.classList.remove('scrolled');
        }
    }

    const siteHeaderHandler = element => {
        handleScrollClass(document.documentElement, element, isScrolled(document.documentElement, HEADER_SCROLL_OFFSET));
    };

    // Reset Classes
    siteHeaderHandler(document.querySelector('#site-header'));

    // Scroll Listeners
    window.addEventListener('scroll', () => {
        siteHeaderHandler(document.querySelector('#site-header'));
    });

    const formieForms = document.querySelectorAll('form');
    if (formieForms.length > 0) {
        axios().then( request => {
                formieForms.forEach(form => {
                    let csrfInput = form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');
                    let formHandleInput = form.querySelector('input[name="handle"]');
                    let formHandle = formHandleInput.value;
                    request.get(`/actions/formie/forms/refresh-tokens?form=${formHandle}`)
                        .then(result => { return result.data })
                        .then(result => {
                            csrfInput.outerHTML = result.csrf.input;
                            // JavaScript captcha
                            if (result.captchas && result.captchas.javascript) {
                                let jsCaptcha = result.captchas.javascript;
                                form.querySelector('input[name="' + jsCaptcha.sessionKey + '"]').value = jsCaptcha.value;
                            }
                            // Find the Duplicate captcha hidden input, so we can update it
                            if (result.captchas && result.captchas.duplicate) {
                                // Duplicate captcha
                                let duplicateCaptcha = result.captchas.duplicate;
                                form.querySelector('input[name="' + duplicateCaptcha.sessionKey + '"]').value = duplicateCaptcha.value;
                            }
                            if (form.form && form.form.formTheme) {
                                form.form.formTheme.updateFormHash();
                            }
                        })
                        .catch(error => console.error(error));
                });
            })
            .catch((error) => {
                console.log(`Unable to load axios: ${error}`);
            });
    }

});